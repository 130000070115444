<template>
  <div class="order">
    <div class="main-page__header header">
      <div class="container">
        <div class="header__inner">
          <div class="header__info">
            <div class="header__logo">
              <HeaderIcon name="logo" />
            </div>
            <HeaderLinks />
          </div>
          <div class="header__actions">
            <div
              v-if="this.getQueryParam === 'game' && windowWidth > 550"
              class="header__actions-username"
            >
              {{ getUserName }}
            </div>
          </div>
        </div>
        <PaymentsHeader v-if="adminRoles.includes(getUserRole)" />
        <Payments v-else />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import HeaderIcon from "@/components/UI/HeaderIcon.vue";
import HeaderLinks from "@/components/header/HeaderLinks.vue";
import PaymentsHeader from "@/components/payments/payments-header/PaymentsHeader.vue";

export default {
  name: "payments-layout",
  components: { PaymentsHeader, HeaderLinks, HeaderIcon },
  data() {
    return {
      windowWidth: window.innerWidth,
      adminRoles: ["rgp", "admin", "director", "sale_director"],
    };
  },
  computed: {
    getUserRole() {
      return this.$store.getters["authModule/getUserRole"];
    },
    getQueryParam() {
      return this.$route.name;
    },
    getUserName() {
      return this.$store.state.authModule.user.name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
