import { LoginApiInstance, DefaultApiInstance } from "../index";

export const AuthApi = {
  login(login, password, accept) {
    let url = "user/login";
    if (accept.length) {
      url += accept;
    }
    const data = { login, password };
    return LoginApiInstance.post(url, data);
  },

  logout() {
    const url = "user/logout";
    return DefaultApiInstance.post(url);
  },

  getAuthUser() {
    const url = "/user";
    return DefaultApiInstance.get(url);
  },

  getAuthUserData() {
    const url = "/user?with[]=roles&with[]=supervisors";
    return DefaultApiInstance.get(url);
  },
};
